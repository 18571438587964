$(document).on('turbolinks:load', function() {
  // docu: https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#class-names

  var locations = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/location_search?query=%QUERY',
      wildcard: '%QUERY',
      cache: false
    }
  });

  var search = $('.location-select');

  if(!search) {
    return;
  }

  var searchForm = search.find('#locationSearchForm');
  var searchField = search.find('.m-search__field');
  var searchInputField = searchField.find('#location_query');
  var minLength = 3;

  searchInputField.typeahead({
    highlight: true,
    minLength: minLength,
    hint: false,
    classNames: {
      input: 'm-search__input',
      menu: 'm-search__menu',
      dataset: 'm-search__dataset',
      suggestion: 'm-search__suggestion',
      cursor: 'is-selected',
      hint: 'm-search__hint'
    }
  }, {
    source: locations,
    templates: {
      suggestion: function(data) {
        var containerClass = 'm-search__suggestion';
        var iconMarkup = '';
        // TODO: Pick better SVC icons
        if(data['result_type'] === 'area') {
          containerClass += ' is-merchant';
          iconMarkup = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M20.5 3l-.16.03L15 5.1 9 3 3.36 4.9a.5.5 0 00-.36.48V20.5a.5.5 0 00.5.5l.16-.03L9 18.9l6 2.1 5.64-1.9a.5.5 0 00.36-.48V3.5a.5.5 0 00-.5-.5M10 5.47l4 1.4v11.66l-4-1.4V5.47m-5 .99l3-1.01v11.7l-3 1.16V6.46m14 11.08l-3 1.01V6.86l3-1.16v11.84z"></path></svg>';
        } else {
          iconMarkup = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M10 2v2.26l2 1.33V4h10v15h-5v2h7V2H10M7.5 5L0 10v11h15V10L7.5 5M14 6v.93L15.61 8H16V6h-2m4 0v2h2V6h-2M7.5 7.5L13 11v8h-3v-6H5v6H2v-8l5.5-3.5M18 10v2h2v-2h-2m0 4v2h2v-2h-2z"></path></svg>';
        }

        return '<div class="' + containerClass + '">' + iconMarkup + '<div class="m-search__title">' + data.title + '</div></div>';
      }
    },
    display: function(suggestion){
      return suggestion['title'];
    },
    limit: 10
  })
  .on('input', function(event) {
    if(event.target.value.length < minLength) {
      searchField.removeClass('m-search__field--opened');
    }
  })
  .bind('typeahead:open', function() {
    var suggestions            = searchField.find('.m-search__suggestion');

    if(suggestions.length > 0) {
      searchField.addClass('m-search__field--opened');
    }
  })
  .bind('typeahead:close', function() {
    searchField.removeClass('m-search__field--opened');
  })
  .bind('typeahead:render', function(evemt, suggestion) {
    if(suggestion.length > 0) {
      searchField.addClass('m-search__field--opened');
    } else {
      searchField.removeClass('m-search__field--opened');
    }
  })
  .bind('typeahead:select', function(evemt, suggestion) {
    searchForm.submit();
  })
  .bind('typeahead:asyncrequest', function(evemt, suggestion) {
    // Start Data Request
    searchField.removeClass('m-search__field--opened');
    searchField.addClass('m-search__field--loading');
  })
  .bind('typeahead:asyncreceive', function(evemt, query, suggestion) {
    // Get Results from Data Request
    searchField.removeClass('m-search__field--loading');
  });

  // Add last field with overall location selection
  var suggestionsFooter = searchField.find('.m-search__suggestion--footer');

  if(suggestionsFooter) {
    var suggestionsFooterValue = suggestionsFooter.find('.tt-highlight').text();

    suggestionsFooter.on('click select', function() {
      searchInputField.val(suggestionsFooterValue);
      searchForm.submit();
    });
  }
});
