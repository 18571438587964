'use strict';

var mapInitiated = false;

function initMap(callback) {
  const mapContainer = document.querySelector('#storemap');
  const mapCoordinates = new google.maps.LatLng(mapContainer.dataset.lat, mapContainer.dataset.lng);

  const mapOptions = {
    center: mapCoordinates,
    zoom: 15,
    heading: 90,
    tilt: 45,
    streetViewControl: false,
    gestureHandling: 'cooperative'
  };

  const map = new google.maps.Map(mapContainer, mapOptions);

  map.setTilt(45);

  new google.maps.Marker({
    position: mapCoordinates,
    animation: google.maps.Animation.DROP,
    color: mapContainer.dataset.markerColor,
    // icon: {
    //   url: '//maps.google.com/mapfiles/ms/icons/' + mapContainer.dataset.markerColor + '.png'
    // },
    // label: {
    //   text: mapContainer.dataset.markerLabel,
    //   color: 'white',
    //   fontSize: '12px'
    // },
    map: map
  });

  mapInitiated = true;

  if(typeof callback === 'function') {
    return callback();
  }
};

function enableMapButton() {
  const mapContainer = document.querySelector('#storemap');
  var mapButton = document.querySelector('#toggleMap');

  if(!mapButton || !mapContainer) {
    return;
  }

  var toggleMap = function(e) {
    e.preventDefault();

    if(mapInitiated === false) {
      initMap();
    }

    document.body.classList.toggle('with-map');
  }

  mapButton.addEventListener('click', toggleMap);
}

window.initializeGoogleMaps = function (scriptUrl) {
  var mapsScript = document.createElement('script');
  mapsScript.src = scriptUrl;
  document.head.append(mapsScript);

  mapsScript.onload = function() {
    enableMapButton();

    if(document.body.classList.contains('with-map')) {
      if(mapInitiated === false) {
        initMap();
      }
    }
  };
};
