'use strict';
/* global mdDateTimePicker, moment */

/* ┌──────────────────────────────────┐
 *   OPTIMIZE FORM INPUTS V2.0.0
 * └──────────────────────────────────┘
 */

var prepareInputs = function() {
  var form = document.querySelector('.m-form');

  if(!form) {
    return;
  }

  var selectedInputs = form.querySelectorAll('.m-form__input:not(.m-form__select)');

  function setFilledClass(input) {
    if(input.value || input.getAttribute('placeholder')) {
      input.classList.add('is-filled');
    } else {
      input.classList.remove('is-filled');
    }
  }

  function setEditedClass(e) {
    e.target.classList.add('is-edited');
    e.target.removeEventListener('blur', setEditedClass);
    e.target.removeEventListener('keydown', setEditedClass);
  }

  for(var i = 0; i < selectedInputs.length; i++) {
    /* file upload */
    if(selectedInputs[i].classList.contains('m-form__input--file')) {
      const fileInput = selectedInputs[i].querySelector('input[type="file"]');
      const fileInputLabel = selectedInputs[i].querySelector('div');

      fileInput.addEventListener('change', function(event) {
        const eventFilePath = event.target.value;
        const fileName = eventFilePath.split(/(\\|\/)/g).pop();
        fileInputLabel.innerHTML = fileName;
      });

      continue;
    }

    setFilledClass(selectedInputs[i]);

    /* jshint loopfunc:true */
    selectedInputs[i].addEventListener('focusout', function(event) {
      var input = event.target;
      var value = input.value;

      setFilledClass(input);

      if(value || input.getAttribute('placeholder')) {
        input.classList.add('is-filled');
      } else {
        input.classList.remove('is-filled');
      }
    });

    if(selectedInputs[i].value === '' && selectedInputs[i].hasAttribute('required')) {
      selectedInputs[i].addEventListener('blur', setEditedClass);
      selectedInputs[i].addEventListener('keydown', setEditedClass);
    }
  }
};

document.addEventListener('turbolinks:load', function() {
  prepareInputs();
});
