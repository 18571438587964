$(document).on('turbolinks:load', function() {
  // docu: https://github.com/twitter/typeahead.js/blob/master/doc/jquery_typeahead.md#class-names

  var products = new Bloodhound({
    datumTokenizer: Bloodhound.tokenizers.whitespace,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    remote: {
      url: '/search/autocomplete?query=%QUERY',
      wildcard: '%QUERY',
      cache: false
    }
  });

  var search = $('.main-search');

  if(!search) {
    return;
  }

  var searchForm = search.find('#searchform');
  var searchField = search.find('.m-search__field');
  var searchInputField = searchField.find('#query');
  var minLength = 2;

  searchInputField.typeahead({
    highlight: true,
    minLength: minLength,
    hint: false,
    classNames: {
      input: 'm-search__input',
      menu: 'm-search__menu',
      dataset: 'm-search__dataset',
      suggestion: 'm-search__suggestion',
      cursor: 'is-selected',
      hint: 'm-search__hint'
    }
  }, {
    source: products,
    templates: {
      suggestion: function(data) {
        var containerClass = 'm-search__suggestion';
        var iconMarkup = '';
        var type = 'article';

        if(data['result_type'] === 'shop') {
          containerClass += ' is-merchant';
          iconMarkup = '<svg xmlns="http://www.w3.org/2000/svg" class="m-icon" width="24" height="24" viewBox="0 0 24 24"><path d="M5.06 3c-.43 0-.84.14-1.22.42s-.6.64-.7 1.08L2.11 8.91a3.19 3.19 0 00.61 2.86l.28.28V19c0 .5.2 1 .61 1.39S4.5 21 5 21h14c.5 0 1-.2 1.39-.61S21 19.5 21 19v-6.95l.28-.28c.66-.82.86-1.77.61-2.86L20.86 4.5c-.13-.44-.36-.8-.73-1.08A1.88 1.88 0 0018.94 3H5.06m13.83 1.97l1.08 4.41c.09.43 0 .82-.28 1.17-.25.31-.56.45-.94.45-.31 0-.58-.1-.8-.34-.22-.23-.34-.5-.37-.82L16.97 5l1.92-.03M5.06 5h1.97l-.61 4.84C6.3 10.63 5.91 11 5.25 11c-.41 0-.72-.14-.94-.45a1.35 1.35 0 01-.28-1.17L5.06 5m3.99 0H11v4.7c0 .35-.11.65-.36.92-.25.26-.56.38-.94.38-.34 0-.63-.12-.86-.41S8.5 10 8.5 9.66V9.5L9.05 5M13 5h1.95l.55 4.5c.08.42 0 .77-.29 1.07-.26.3-.6.43-1.01.43-.31 0-.59-.12-.84-.38A1.3 1.3 0 0113 9.7V5m-5.55 7.05c.63.62 1.41.95 2.35.95.84 0 1.58-.33 2.2-.95.69.62 1.45.95 2.3.95.87 0 1.62-.33 2.25-.95a2.9 2.9 0 002.25.95h.23v6H5v-6h.25c.91 0 1.64-.33 2.2-.95z"></path></svg>';
        } else if(data['result_type'] === 'vendor') {
          containerClass += ' is-vendor';
          iconMarkup = '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M16,17H5V7H16L19.55,12M17.63,5.84C17.27,5.33 16.67,5 16,5H5A2,2 0 0,0 3,7V17A2,2 0 0,0 5,19H16C16.67,19 17.27,18.66 17.63,18.15L22,12L17.63,5.84Z" /></svg>';
        } else if(data['result_type'] === 'category') {
          containerClass += ' is-category';
          iconMarkup = '<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M11,13.5V21.5H3V13.5H11M9,15.5H5V19.5H9V15.5M12,2L17.5,11H6.5L12,2M12,5.86L10.08,9H13.92L12,5.86M17.5,13C20,13 22,15 22,17.5C22,20 20,22 17.5,22C15,22 13,20 13,17.5C13,15 15,13 17.5,13M17.5,15A2.5,2.5 0 0,0 15,17.5A2.5,2.5 0 0,0 17.5,20A2.5,2.5 0 0,0 20,17.5A2.5,2.5 0 0,0 17.5,15Z" /></svg>';
        } else {
          containerClass += ' is-product';
          iconMarkup = '<svg xmlns="http://www.w3.org/2000/svg" class="m-icon" width="24" height="24" viewBox="0 0 24 24"data-icon="search"><path d="M9.5 3A6.5 6.5 0 0 1 16 9.5c0 1.61-.59 3.09-1.56 4.23l.27.27h.79l5 5-1.5 1.5-5-5v-.79l-.27-.27A6.516 6.516 0 0 1 9.5 16 6.5 6.5 0 0 1 3 9.5 6.5 6.5 0 0 1 9.5 3m0 2C7 5 5 7 5 9.5S7 14 9.5 14 14 12 14 9.5 12 5 9.5 5z"></path></svg>';
        }

        return '<' + type + ' class="' + containerClass + '">' + iconMarkup + '<div class="m-search__title">' + data.title + '</div></' + type + '>';
      }
    },
    display: function(suggestion){
      return suggestion['title'];
    },
    limit: 12
  })
  .on('input', function(event) {
    if(event.target.value.length < minLength) {
      searchField.removeClass('m-search__field--opened');
    }
  })
  .bind('typeahead:open', function() {
    var suggestions = searchField.find('.m-search__suggestion');

    if(suggestions.length > 0) {
      searchField.addClass('m-search__field--opened');
    }
  })
  .bind('typeahead:close', function() {
    searchField.removeClass('m-search__field--opened');
  })
  .bind('typeahead:render', function(evemt, suggestion) {
    if(suggestion.length > 0) {
      searchField.addClass('m-search__field--opened');
    } else {
      searchField.removeClass('m-search__field--opened');
    }
  })
  .bind('typeahead:select', function(evemt, suggestion) {
    searchForm.submit();
  })
  .bind('typeahead:asyncrequest', function(evemt, suggestion) {
    // Start Data Request
    searchField.removeClass('m-search__field--opened');
    searchField.addClass('m-search__field--loading');
  })
  .bind('typeahead:asyncreceive', function(evemt, query, suggestion) {
    // Get Results from Data Request
    searchField.removeClass('m-search__field--loading');
  });

  // Set auto focus on front page
  if($('body').hasClass('l-view--index')) {
    searchInputField.focus();
  }
});
