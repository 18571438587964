$(document).on('turbolinks:load', function(){

$(function(){
  var locSelect = document.querySelector('.m-locselect');

  if(!locSelect) {
    return;
  }

  var locList = locSelect.querySelector('.jst-list');
  //var cities = locList.children;
  var locButton = locSelect.querySelector('.jst-button');
  var labelCity = locButton.querySelector('.jst-cityjst-city');
  var labelLetter = locButton.querySelector('.jst-letter');
  var input = document.getElementById('location_query');

  var observeEscapeKey = function(e) {
    if(e.keyCode === 27) {
      locSelect.classList.toggle('is-opened');
    }
  };

  var toggleList = function() {
    if(locSelect.classList.contains('is-opened')) {
      locSelect.classList.remove('is-opened');

      document.removeEventListener('keyup', observeEscapeKeyfunction);
    } else {
      locSelect.classList.add('is-opened');
      input.focus();

      document.addEventListener('keyup', observeEscapeKey);
    }
  };

  var selectNewCity = function(e) {
    var city = e.target;
    var cityKey = city.dataset.city;

    locList.querySelector('.is-active').classList.remove('is-active');
    city.classList.add('is-active');

    labelCity.innerHTML = city.innerHTML;
    labelLetter.innerHTML = city.dataset.letter;

    toggleList();

    $.getJSON( "/set-location?location_code="+city.dataset.city+"&location_letter="+city.dataset.letter+"&location_name="+city.innerHTML, function( data ) {
      console.log(data);
    });
  };

  locButton.addEventListener('click', toggleList);
}())
});
