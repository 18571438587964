'use strict';

var Typed = require('./typed.min.js');

document.addEventListener('turbolinks:load', function() {
  var searchStr = document.getElementById('search-strings');
  var queryInput = document.getElementById('query');

  if(queryInput) {
    if(searchStr) {
      searchStr = searchStr.innerText;
      searchStr = JSON.parse(searchStr);
    } else {
      searchStr = [''];
    }

    new Typed('#query', {
      strings: searchStr,
      typeSpeed: 100,
      backSpeed: 25,
      attr: 'placeholder',
      bindInputFocusEvents: false,
      showCursor: false,
      loop: true
    });
  }
});
